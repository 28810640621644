import React, { useEffect } from "react";
import Email from "./items/email";
import Phone from "./items/phone";
import PostalAdress from "./items/postalAdress";
import Instagram from "./items/instagram";
import Youtube from "./items/youtube";
import Linkedin from "./items/linkedin";

const config = require("../../config.json");
const client = config.APP_CLIENT;
const imgName = config.APP_IMG_NAME;
const gifName = config.APP_GIF_NAME;
const compagnyName = config.APP_COMPAGNY_NAME;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;
const colorBackgroundTable = config.APP_COLORBACKGROUNDTABLE;
const widthImage = config.APP_WIDTHIMAGE;

function constructImgUrl(client, ext) {
  if (ext) {
    return `https://files.myclientisrich.com/${client}/${imgName}.${ext}`;
  }
}

function constructGifUrl(client, ext) {
  if (ext) {
    return `https://files.myclientisrich.com/${client}/${gifName}.${ext}`;
  }
}

const SignatureFormatter = ({ user, message, typeImage }) => {
  useEffect(() => {
    document.title = `${compagnyName} | Signature`;
    const metaCharset = document.createElement('meta');
    metaCharset.setAttribute('charSet', 'utf-8');
    document.head.appendChild(metaCharset);
    return () => {
      document.head.removeChild(metaCharset);
    };
  }, []);

  let urlUploadsImg = "";
  if (!user.urlImage) {
    urlUploadsImg = constructImgUrl(client, typeImage === "static" ? 'png' : 'gif');
  }

  let urlUploadsGif = "";
  if (!user.urlGif) {
    urlUploadsGif = constructGifUrl(client, 'gif');
  }

  return (
    <div>
      <div className='wrapper-table'>
        <table style={{ width: "600px", borderCollapse: "collapse"}}>
          <tbody>
            <tr>
              <td style={{ padding: "10px" }}>
                <table style={{ width: "100%", background: `url('https://files.myclientisrich.com/serue/bg_white.png') no-repeat center center / cover`, borderRadius: "10px" }}>
                  <tbody>
                    <tr>
                      <td style={{ width: widthImage, height: widthImage, padding: "10px", verticalAlign: "middle" }}>
                        <a href={`https://${user.website}`} target='_blank' rel="noreferrer">
                          <img src={urlUploadsImg} alt="logo" style={{ width: widthImage, height: "auto", display: "block", margin: "auto" }} />
                        </a>
                      </td>
                      <td style={{ width: "149px", height: "140px", verticalAlign: "middle" }}>
                        <a href={`https://${user.website}`} target='_blank' rel="noreferrer">
                          <img src={urlUploadsGif} alt="logo" style={{ width: "149px", height: "auto", display: "block", margin: "auto" }} />
                        </a>
                      </td>
                      <td style={{ padding: "20px", fontFamily: "Arial", lineHeight: "1.45", borderRadius: "10px", border: "1px solid #18ADE8", verticalAlign: "top" }}>
                        <p style={{ fontSize: "18px", margin: "0", fontWeight: "700", lineHeight: "1", display: "inline-block", color: compagnyColorPrimary }}>
                          {user.firstname}
                        </p>
                        <p style={{ fontSize: "18px", margin: "0", fontWeight: "400", lineHeight: "1", display: "inline-block", color: compagnyColorPrimary }}>
                          &nbsp;{user.lastname}
                        </p>
                        <p style={{ margin: "3px 0 5px 0", fontSize: "14px", color: compagnyColorPrimary, fontWeight: "400" }}>{user.job}</p>
                        <table>
                          <tbody>
                            <tr>
                              <td>{user.phone ? <Phone phone={user.phone} /> : null}</td>
                            </tr>
                            <tr>
                              <td>{user.phonePerso ? <Phone phone={user.phonePerso} /> : null}</td>
                            </tr>
                          </tbody>
                        </table>
                        {user.postalAdress ? <PostalAdress adress={user.postalAdress} adress2={user.postalAdress2} /> : null}
                        <div>
                          <a href={user.websiteUrl} style={{ color: compagnyColorPrimary, fontSize: "13px", textDecoration: "none" }}>{user.websiteTitle}</a>
                        </div>
                        <table>
                          <tbody>
                            <tr style={{ verticalAlign: "middle" }}>
                              {user.linkedin ? (
                                <td style={{ textAlign: "center" }}><Linkedin linkedin={user.linkedin} /></td>
                              ) : null}
                              {user.instagram ? (
                                <td style={{ textAlign: "center" }}><Instagram instagram={user.instagram} /></td>
                              ) : null}
                              {user.youtube ? (
                                <td style={{ textAlign: "center" }}><Youtube youtube={user.youtube} /></td>
                              ) : null}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ borderSpacing: 0, marginTop: "20px" }}>
          <tbody>
            <tr>
              <td>
                <img src={`https://files.myclientisrich.com/${client}/bandeau.jpg`} alt="bandeau" width="600" height="74" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SignatureFormatter;
