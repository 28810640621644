import React, { useState } from "react";
import SignatureFormatter from "./components/SignatureFormatter";
import "./style.css";

const Wrapper = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const Container = {
  width: "100%",
  display: "block",
};
const InputWrapper = {
  margin: "0 0",
  paddingTop: "20px",
};
const leftPart = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%"
}
const inputWrapper = {
  display: "flex",
  flexDirection: "row",
  gap: "15px"
}

// default data to print
const userData = {
  firstname: "Prénom",
  lastname: "Nom",
  urlImage: "",
  urlGif: "",
  job: "Titre du poste",
  phone: "+33 (0)3 88 33 60 20",
  phonePerso: "",
  postalAdress: "Espace Européen de l’Entreprise",
  postalAdress2: "4 rue de Vienne, 67300 Schiltigheim",
  websiteUrl: "https://www.serue.com",
  websiteTitle: "www.serue.com",
  linkedin: "https://fr.linkedin.com/company/serue-ingenierie",
  youtube: "https://www.youtube.com/@SERUEingenierie",
  instagram: "https://www.instagram.com/serueing/",
};

export default function App() {
  const [user, setUser] = useState(userData);
  const [isChecked, setIsChecked] = useState(false);
  const [typeImage, setTypeImage] = useState('static');
  const [gifChecked, setGifChecked] = useState(false);
  const [staticChecked, setStaticChecked] = useState(false);

  const handleGifChange = (event) => {
    setGifChecked(event.target.checked);
    setStaticChecked(false);
    setTypeImage(event.target.value);
  };

  const handleStaticChange = (event) => {
    setGifChecked(false);
    setStaticChecked(event.target.checked);
    setTypeImage(event.target.value);
  };

  const handleOnChange = e => {
    const { name, value } = e.target;
    setIsChecked(!isChecked);
  };

  const userInfoChange = e => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  function copyToClipboard() {
    let tableToCopy = document.querySelector('.wrapper-table')
    let range = document.createRange();
    range.selectNode(tableToCopy);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }

  function responseCopy() {
    let button = document.querySelector('button');
      button.classList.add('copied');
    setTimeout(() => {
      button.classList.remove('copied');
    }, 2000);
  }

  return (
    <div style={Wrapper}>
      <div style={Container}>
        <section className="formulaire">
          <div style={leftPart}>
            <SignatureFormatter user={user} message={isChecked} typeImage={typeImage} />
          </div>
          <div className="rightPart">
            <h1 className="title">Signature Generator</h1>
            <h2 className="title">By my client is rich</h2>
            <p className="inputLabel">Noms</p>

            {/* INPUT RIGHT SIDE  */}

            {/* ***************          NOM ET PRENOM        *************** */}
            <div style={inputWrapper}>
              <input
                name="lastname"
                onChange={userInfoChange}
                value={user.lastname}
                placeholder="Nom"
                onBlur={() => {
                  user.lastname === "" ? setUser({ ...user, lastname: userData.lastname }) : setUser({ ...user, lastname: user.lastname });
                }}
                onFocus={() => {
                  user.lastname === userData.lastname ? setUser({ ...user, lastname: "" }) : setUser({ ...user, lastname: user.lastname });
                }}
              />
              <input
                name="firstname"
                onChange={userInfoChange}
                value={user.firstname}
                placeholder="Prénom"
                onBlur={() => {
                  user.firstname === "" ? setUser({ ...user, firstname: userData.firstname }) : setUser({ ...user, firstname: user.firstname });
                }}
                onFocus={() => {
                  user.firstname === userData.firstname ? setUser({ ...user, firstname: "" }) : setUser({ ...user, firstname: user.firstname });
                }}
              />
            </div>

            {/* ***************          TITRE DU POSTE        *************** */}
            <div style={InputWrapper}>
              <input
                name="job"
                onChange={userInfoChange}
                value={user.job}
                placeholder="Titre du poste"
                onBlur={() => {
                  user.job === "" ? setUser({ ...user, job: userData.job }) : setUser({ ...user, job: user.job });
                }}
                onFocus={() => {
                  user.job === userData.job ? setUser({ ...user, job: "" }) : setUser({ ...user, job: user.job });
                }}
                />
            </div>



            {/* ***************          TELEPHONE        *************** */}
            <div style={InputWrapper}>
              <input
                name="phonePerso"
                onChange={userInfoChange}
                value={user.phonePerso}
                placeholder="Téléphone"
                onBlur={() => {
                  user.phonePerso === "" ? setUser({ ...user, phonePerso: userData.phonePerso }) : setUser({ ...user, phonePerso: user.phone });
                }}
                onFocus={() => {
                  user.phonePerso === userData.phonePerso ? setUser({ ...user, phonePerso: "" }) : setUser({ ...user, phonePerso: user.phone });
                }}
              />
            </div>
            {/* ***************          SITE INTERNET        *************** */}
            {/* <p className="inputLabel">Site internet</p>
            <div style={InputWrapper}>
              <input
                name="website"
                onChange={userInfoChange}
                value={user.website}
                placeholder="Site internet"
                onBlur={() => {
                  user.website === "" ? setUser({ ...user, website: userData.website }) : setUser({ ...user, website: user.website });
                }}
                onFocus={() => {
                  user.website === userData.website ? setUser({ ...user, website: "" }) : setUser({ ...user, website: user.website });
                }}
              />
            </div> */}

            {/* ***************          RESEAUX SOCIAUX        *************** */}
            {/* <p className="inputLabel">Social</p> */}
            {/* ***************          LINKEDIN        *************** */}
            {/* <div style={InputWrapper}>
              <input
                name="linkedin"
                onChange={userInfoChange}
                value={user.linkedin}
                placeholder="Linkedin"
                onBlur={() => {
                  user.linkedin === "" ? setUser({ ...user, linkedin: userData.linkedin }) : setUser({ ...user, linkedin: user.linkedin });
                }}
                onFocus={() => {
                  user.linkedin === userData.linkedin ? setUser({ ...user, linkedin: "" }) : setUser({ ...user, linkedin: user.linkedin });
                }}
              />
            </div> */}

            {/* ***************          FACEBOOK        *************** */}
            {/* <div style={InputWrapper}>
              <input
                name="youtube"
                onChange={userInfoChange}
                value={user.youtube}
                placeholder="Youtube"
                onBlur={() => {
                  user.youtube === "" ? setUser({ ...user, youtube: userData.youtube }) : setUser({ ...user, youtube: user.youtube });
                }}
                onFocus={() => {
                  user.youtube === userData.youtube ? setUser({ ...user, youtube: "" }) : setUser({ ...user, youtube: user.youtube });
                }}
              />
            </div> */}

            {/* ***************          INSTAGRAM        *************** */}
            {/* <div style={InputWrapper}>
              <input
                name="instagram"
                onChange={userInfoChange}
                value={user.instagram}
                placeholder="Instagram"
                onBlur={() => {
                  user.instagram === "" ? setUser({ ...user, instagram: userData.instagram }) : setUser({ ...user, instagram: user.instagram });
                }}
                onFocus={() => {
                  user.instagram === userData.instagram ? setUser({ ...user, instagram: "" }) : setUser({ ...user, instagram: user.instagram });
                }}
              />
            </div> */}


            <button
              className="copyButton"
              onClick={() => {
                copyToClipboard();
                responseCopy();
              }}
            >
              <p className="btnCopy"></p>
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}
