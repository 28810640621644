import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const imgSocial = {
  objectFit: "contain",
  verticalAlign: "middle",
};


export default function Youtube({youtube}) {
  const youtubeUrl = youtube.startsWith('http://') || youtube.startsWith('https://')
  ? youtube
  : `https://${youtube}`;
  return (
    <a href={youtubeUrl}
    >
      <img
      style={imgSocial}
      src={`https://files.myclientisrich.com/${client}/youtube.png`}
      alt="logo youtube"
      width="22px"
      height="14px"
      ></img>
    </a>
  );
}
