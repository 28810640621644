import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const MailLink = {
  textDecoration: "none",
  color: compagnyColorPrimary,
  fontSize: "15px",
  margin: "0px 0px 0px",
};
const iconLogo = {
  marginRight: "10px",
  transform: "translateY(3px)",
};

export default function Email(email) {
  return (
    <table style={{borderSpacing: 0}}>
      <tbody>
        <tr>
          <td style={{verticalAlign: 'middle'}}>
            <img
              src={`https://files.myclientisrich.com/${client}/mail.png`}
              style={iconLogo}
              alt="logo email"
              width="20"
              height="15"
            ></img>
          </td>
          <td>
            <a href={`mailto:${email.email}`} style={MailLink}>
              {email.email}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
