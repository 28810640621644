import React from "react";

const config = require("../../../config.json")
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const PhoneLink = {
  textDecoration: "none",
  color: compagnyColorPrimary,
  paddingRigth: "5px",
  marginRigth: "5px",
  lineHeight: "0",
  fontSize: "13px",
  fontWeight: "700"
};

function telWithoutSpaces(number) {
  return number.replace(/\s/g, "");
}

export default function Phone(phone) {
  return (
      <a href={`tel:${telWithoutSpaces(phone.phone)}`}
        style={PhoneLink}
        >
        {phone.phone}
      </a>
  );
}
