import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const imgSocial = {
  objectFit: "contain",
  verticalAlign: "middle",
  marginRight: "10px",
};


export default function Instagram({instagram}) {
  const instagramUrl = instagram.startsWith('http://') || instagram.startsWith('https://')
  ? instagram
  : `https://${instagram}`;
  return (
    <a href={instagramUrl}
    >
      <img
        style={imgSocial}
        src={`https://files.myclientisrich.com/${client}/insta.png`}
        alt="logo instagram"
        width="17px"
        height="17px"
        ></img>
    </a>
  );
}
