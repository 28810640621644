import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const imgSocial = {
  objectFit: "contain",
  verticalAlign: "middle",
  marginRight: "10px",
};

export default function Linkedin({linkedin}) {
  const linkedinUrl = linkedin.startsWith('http://') || linkedin.startsWith('https://')
  ? linkedin
  : `https://${linkedin}`;

  return (
    <a href={linkedinUrl}
    >
      <img
        style={imgSocial}
        src={`https://files.myclientisrich.com/${client}/linkedin.png`}
        alt="logo linkdedin"
        width="17px"
        height="17px"
      ></img>
    </a>
  );
}
