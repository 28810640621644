import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const postalAdressStyle = {
  textDecoration: "none",
  color: compagnyColorPrimary,
  fontSize: "12px",
  margin: "10px 0px 10px",
  lineHeight: "14px",
};

export default function PostalAdress(adress, adress2) {
  return (
    <p  style={postalAdressStyle}>
      {adress.adress}<br />
      {adress.adress2}
    </p>
  );
}
